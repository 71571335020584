import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './dps-rankings.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const HSRDPSRankingsPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-dps-rankings'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>DPS Rankings</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/starrail/categories/category_dps.png"
            alt="DPS Rankings"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Solo DPS Rankings</h1>
          <h2>
            You can always trust in math. Check the simulated Solo DPS rankings
            for Honkai: Star Rail characters.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Announcement" />
        <p>
          The DPS Rankings has been removed and soon you will be able to find
          the calculations for each character on their profile!
        </p>
      </div>
    </DashboardLayout>
  );
};

export default HSRDPSRankingsPage;

export const Head: React.FC = () => (
  <Seo
    title="Solo DPS Rankings | Honkai: Star Rail | Prydwen Institute"
    description="You can always trust in math. Check the simulated Solo DPS rankings for Honkai: Star Rail characters."
  />
);
